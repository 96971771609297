import * as React from "react";
import { Link } from "gatsby";

import Ch from "../../components/Chord.js";
import Chorus from "../../components/Chorus.js";
import Columns from "../../components/Columns.js";
import { Helmet } from "react-helmet";
import Layout from "../../components/Layout";
import Verse from "../../components/Verse.js";

export default function TheCraneWife3() {
  return (
    <Layout>
      <Helmet title="The Crane Wife 3 | The Decemberists" />

      <h1>The Crane Wife 3</h1>

      <h2>The Decemberists</h2>

      <h4>Key: G</h4>

      <h4>Tempo: 92</h4>

      <Link to="/">List</Link>

      <Columns columns="2">
        <Verse>
          <p>
            <Ch>D</Ch>And un<Ch>A</Ch>der the boughs un<Ch>G</Ch>bowed
          </p>
          <p>
            <Ch>D</Ch>All clothed in<Ch>A</Ch> a snowy <Ch>G</Ch>shroud
          </p>
          <p>
            <Ch>D</Ch>She had no <Ch>A</Ch>heart, so hard<Ch>G</Ch>ened
          </p>
          <p>
            <Ch>D</Ch>All un<Ch>A</Ch>der the boughs un<Ch>G</Ch>bowed
          </p>
        </Verse>
        <Verse>
          <p>
            <Ch>D</Ch>Each fea<Ch>A</Ch>ther it fell from <Ch>G</Ch>skin
          </p>
          <p>
            <Ch>D</Ch>'Til thread<Ch>A</Ch>bare, as she grew <Ch>G</Ch>thin
          </p>
          <p>
            <Ch>D</Ch>How were my <Ch>A</Ch>eyes so blind<Ch>G</Ch>ed?
          </p>
          <p>
            <Ch>D</Ch>Each fea<Ch>A</Ch>ther it fell from <Ch>G</Ch>skin
          </p>
        </Verse>
        <Chorus>
          <p>
            And I will <Ch>D</Ch>hang my head, <Ch>A</Ch>hang my head <Ch>G</Ch>
            low
          </p>
          <p>
            And I will <Ch>D</Ch>hang my head, <Ch>A</Ch>hang my head <Ch>G</Ch>
            low
          </p>
          <p>
            <b>D A G</b>
          </p>
        </Chorus>
        <Verse>
          <p>
            <Ch>D</Ch>A gray sky,<Ch>A</Ch> a bitter <Ch>G</Ch>sting
          </p>
          <p>
            <Ch>D</Ch>A rain cloud,<Ch>A</Ch> a crane on <Ch>G</Ch>wing
          </p>
          <p>
            <Ch>D</Ch>All out be<Ch>A</Ch>yond hori<Ch>G</Ch>zon
          </p>
          <p>
            <Ch>D</Ch>A gray sky,<Ch>A</Ch> a bitter <Ch>G</Ch>sting
          </p>
        </Verse>
        <Chorus>
          <p>
            And I will <Ch>D</Ch>hang my head, <Ch>A</Ch>hang my head <Ch>G</Ch>
            low
          </p>
          <p>
            And I will <Ch>D</Ch>hang my head, <Ch>A</Ch>hang my head <Ch>G</Ch>
            low
          </p>
          <p>
            <b>D A G</b>
          </p>
        </Chorus>
        <Chorus>
          <p>
            And I will <Ch>D</Ch>hang my head, <Ch>A</Ch>hang my head <Ch>G</Ch>
            low
          </p>
          <p>
            And I will <Ch>D</Ch>hang my head, <Ch>A</Ch>hang my head <Ch>G</Ch>
            low
          </p>
          <p>
            <b>D A G</b>
          </p>
        </Chorus>
        <Chorus>
          <p>
            And I will <Ch>D</Ch>hang my head, <Ch>A</Ch>hang my head <Ch>G</Ch>
            low
          </p>
          <p>
            And I will <Ch>D</Ch>hang my head, <Ch>A</Ch>hang my head <Ch>G</Ch>
            low
          </p>
          <p>
            <b>D A G</b>
          </p>
        </Chorus>
      </Columns>
    </Layout>
  );
}
